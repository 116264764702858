import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby";
import { css } from "@emotion/core";
import { css as cssClass } from "emotion";

const Header = ({ siteTitle }) => (
  <header>
    <div
      css={css`
        margin: 0 auto;
        max-width: 960px;
        padding: 1.45rem 1.0875rem;
        text-align: center;
        @media only screen and (max-width: 600px) {
          padding: 1.45rem 0;
        }
      `}
    >
      <a
        href="/"
        css={css`
          text-decoration: none;
        `}
      >
        <h1
          css={css`
            color: #59bfa9;
            font-size: 40pt;
            font-weight: normal;
            font-family: "Grand Hotel", cursive;
            margin: 0;
            @media only screen and (max-width: 600px) {
              font-size: 31pt;
            }
          `}
        >
          Charlotte de Hilster
        </h1>
        <h2
          css={css`
            color: #999999;
            font-size: 15pt;
            font-family: "Dosis", sans-serif;
            @media only screen and (max-width: 600px) {
              font-size: 13pt;
            }
          `}
        >
          USER EXPERIENCE DESIGNER
        </h2>
      </a>
      <div
        style={{
          display: `flex`,
          flexDirection: `row-reverse`,
          boxShadow: `inset 0 -1px 0 lightgrey`
        }}
      >
        <Link
          css={css`
            color: #111111;
            position: relative;
            bottom: -1px;
            padding-left: 8px;
            padding-right: 8px;
            text-decoration: none;
            font-size: 12pt;
            font-family: "Dosis", sans-serif;
            @media only screen and (max-width: 600px) {
              padding-bottom: 8px;
            }
          `}
          activeStyle={{
            borderBottom: `3px solid #59BFA9`,
            fontWeight: `bold`
          }}
          to="/about/"
        >
          OVER MIJ
        </Link>
        <Link
          to="/"
          css={css`
            color: #111111;
            padding-left: 8px;
            position: relative;
            bottom: -1px;
            padding-right: 8px;
            text-decoration: none;
            font-size: 12pt;
            font-family: "Dosis", sans-serif;
            @media only screen and (max-width: 600px) {
              padding-bottom: 8px;
            }
          `}
          getProps={({ isPartiallyCurrent, location }) => {
            if (location.pathname.includes("about")) {
              return null;
            }
            return {
              className: cssClass`
                color: #111111;
                padding-left: 8px;
                position: relative;
                bottom: -1px;
                padding-right: 8px;
                text-decoration: none;
                font-size: 12pt;
                font-family: "Dosis", sans-serif;
                border-bottom: 3px solid #59bfa9;
                font-weight: bold;
                @media only screen and (max-width: 600px) {
                  padding-bottom: 8px;
                }
              `
            };
          }}
        >
          PORTFOLIO
        </Link>
      </div>
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
