import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { css } from "@emotion/core";
import { Helmet } from "react-helmet";

import Layout from "../components/layout";

const AboutPage = () => (
  <StaticQuery
    query={graphql`
      query {
        aboutMe: file(relativePath: { eq: "about-me.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <Helmet>
          <title>Over mij | Charlotte de Hilster</title>
        </Helmet>
        <div
          css={css`
            display: grid;
            grid-template-columns: 1fr 45%;
            grid-column-gap: 24px;
            width: 100%;
            @media only screen and (max-width: 600px) {
              grid-template-columns: 1fr;
            }
          `}
        >
          <Img
            css={css`
              @media only screen and (max-width: 600px) {
                grid-row: 2;
              }
            `}
            fluid={data.aboutMe.childImageSharp.fluid}
          />
          <div
            css={css`
              @media only screen and (max-width: 600px) {
                grid-row: 1;
              }
            `}
          >
            <h3
              style={{
                fontFamily: `Lato, sans-serif`,
                fontWeight: `bold`,
                fontSize: `32pt`
              }}
            >
              Hi!
            </h3>
            <p
              style={{
                fontFamily: `Lato, sans-serif`,
                fontWeight: `lighter`,
                fontSize: `14pt`
              }}
            >
              Ik ben Charlotte, een user experience designer met affiniteit voor
              visual design. Momenteel woon ik in Rotterdam en ben ik sinds 2016
              werkzaam bij Coolblue.
              <br />
              <br />
              Voor ik bij Coolblue van start ging was ik werkzaam bij het bureau
              Kaliber (voorheen Rhinofly) in Utrecht. Hier heb ik 8 jaar bureau
              ervaring opgedaan voor verschillende opdrachtgevers: de overheid,
              nijntje museum, Securitas, Velux, Veteraneninstituut en nog veel
              meer. Momenteel ben ik user experience designer bij Coolblue waar
              ik verantwoordelijk ben voor het UX en visual design van de iOS en
              Android app. Ook probeer ik de website steeds persoonlijker te
              maken voor de gebruikers.
              <br />
              <br />
              Wil je meer over me weten of me uitnodigen voor een gesprek? Je
              kunt me bereiken op:
              <br />
              <a
                css={css`
                  color: #59bfa9;
                  text-decoration: none;
                `}
                href="mailto:charlottedehilster@gmail.com"
              >
                charlottedehilster@gmail.com
              </a>
            </p>
          </div>
        </div>
      </Layout>
    )}
  />
);

export default AboutPage;
